<template>
  <div>
    <Dropdown v-model="selectedQuota"
              :options="quotaTypes"
              optionLabel="label"
              optionValue="code"
              style="width: 100%; margin-bottom: 10px;"
              :onChange="getData"
              placeholder="Seleccionar tipo de cupo"/>

    <h1 v-if="selectedQuota === 1">

      <Accordion style="width: 100%;" :multiple="true" :activeIndex="0">
        <AccordionTab header="Lotería">

          <Dropdown v-model="selectedProduct"
                    :options="products && products.loteria"
                    optionLabel="tproducto"
                    style="width: 100%; margin-bottom: 10px;"
                    :onChange="getData"
                    placeholder="Seleccionar tipo de cupo"/>


          <DataTable :value="quotas && quotas.lottery"
                     editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveLoteria"
                     responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm">
            <Column field="tproductoxsorteo" header="Producto" style="text-align: left;"/>
            <Column field="tlista" header="Lista" bodyStyle="text-align: center;"/>
            <Column field="ttipo_lista" header="Tipo de lista" bodyStyle="text-align: center;"/>
            <Column field="mcupo" header="Monto" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.mcupo) }}
              </template>
            </Column>
            <!--                    <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>-->
          </DataTable>
        </AccordionTab>

        <AccordionTab header="Animalitos">
          <Dropdown v-model="selectedAnimalitos"
                    :options="products && products.animalitos"
                    optionLabel="tproducto"
                    style="width: 100%; margin-bottom: 10px;"
                    :onChange="getData"
                    placeholder="Seleccionar tipo de cupo"/>

          <DataTable :value="quotas && quotas.animalitos"
                     editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveLoteria"
                     responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm">
            <Column field="tproductoxsorteo" header="Producto" style="text-align: left;"/>
            <Column field="mcupo" header="Monto" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.mcupo) }}
              </template>
            </Column>
            <!--                    <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>-->
          </DataTable>
        </AccordionTab>
      </Accordion>

    </h1> <!-- selectedQuota === 1-->

  </div>

  <!--  <div class="flex justify-content-end">-->
  <!--    <Button icon="pi pi-print" class="p-button-raised" :onClick="print"/>-->
  <!--  </div>-->

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import MainService from "@/service/backend/MainService";
import BankingService from "@/service/backend/BankingService";

export default {
  name: "Tab3",
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.mainService = new MainService;
    this.bankingService = new BankingService;
  },
  props: {
    banking: Object,
  },
  data() {
    return {
      loadingTable: false,
      products: null,
      quotas: null,
      quotaTypes: [
        {code: 1, label: 'FILTRO'},
        {code: 2, label: 'DINAMICO'}
      ],
      selectedQuota: null,
      selectedProduct: null,
      selectedAnimalitos:null,
    }
  },
  async mounted() {
    this.selectedQuota = this.banking.id_tipo_cupo;
    await this.getProducts();
    await this.getData();
  },
  methods: {
    async getProducts() {
      this.products = await this.mainService.getProducto({selectedGameReport: 'discriminated',});
      this.selectedProduct = this.products.loteria[0];
      this.selectedAnimalitos = this.products.animalitos[0];
    },
    async getData() {
      this.loadingTable = true;
      this.quotas = await this.bankingService.getQuota({
        id_loteria: this.selectedProduct.id_loteria,
        id_producto: this.selectedProduct.id_producto,
        id_banca: this.banking.id_banca,
        id_loteria_animalitos: this.selectedAnimalitos.id_loteria,
        id_producto_animalitos: this.selectedAnimalitos.id_producto,

      });
      this.loadingTable = false;
    }
    ,

  }

}
</script>

<style scoped lang="scss">
::v-deep( .p-accordion-header) {
  margin: 5px;
}

</style>