<template>
  <div id="overlay" v-if="overlay"></div>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="block_container">
            <div class="bloc1"><h3>Bancas {{ totalRecords && '(' + totalRecords + ')' }}</h3></div>
          </div>
        </div>
      </div>
      <div :class="`col-12 lg:col-${getTableLayoutClass()}`">
        <div class="card p-fluid" id="number-container">
          <!--     Filter goes here     -->
          <Filter
              @get-data="getData"
              page="bankings"
          />
        </div>
      </div>
      <div :class="`col-12 lg:col-${12-getTableLayoutClass()}`">
        <div class="card">
          <Toolbar class="mb-4">
            <template #start>
              <Button label="" icon="pi pi-plus" class="p-button-success mr-2" @click="createBanking"/>
            </template>
            <template #end>

              <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedBankings || !selectedBankings.length"/>

            </template>
          </Toolbar>
          <DataTable :value="bankings" :lazy="true" :paginator="true" :rows="10"
                     v-model:filters="filters" ref="dt"
                     dataKey="id"
                     :totalRecords="totalRecords"
                     :loading="loadingTable" @page="onPage($event)"
                     @sort="onSort($event)"
                     @filter="onFilter($event)"
                     v-model:selection="selectedBankings"
                     :selectAll="selectAll"
                     responsiveLayout="scroll"
                     breakpoint="960px"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                     showGridlines
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25,50]"
                     currentPageReportTemplate="{first} al {last} de {totalRecords}"
                     @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect">

            <template #header>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <h5 class="mb-2 md:m-0 p-as-md-center">Bancas</h5>
                <span class="p-input-icon-left">
                                      <i class="pi pi-search"/>
                                      <InputText v-model="searchFilters['global'].value" placeholder="Buscar..."
                                                 v-on:keyup.enter="onFilter"/>
                                  </span>
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

            <Column field="cstatus_banca" header="Estatus" :sortable="true"
                    bodyStyle="text-align: center; white-space: nowrap !important;">
              <template #body="{data}">
                <span :class="'status-badge '+getEntityStatusClass(data.cstatus_banca)">
                  {{ getEntityStatus(data.cstatus_banca).toUpperCase() }}
                </span>
              </template>
            </Column>


            <Column field="tbanca" header="Nombre" :sortable="true" filterMatchMode="startsWith" ref="id_banca"/>
            <Column field="tcomercializador" header="Bloque" :sortable="true" bodyStyle="text-align: left;"
                    v-if="identity.roleId>2"/>
            <Column field="tcomercializador" header="Bloque" :sortable="true" bodyStyle="text-align: left;"
                    v-if="!identity.entityId"/>
            <Column field="tbanca" header="Banca" :sortable="true" bodyStyle="text-align: left;"/>
            <Column field="bred_propia" header="Externo" :sortable="true" bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.bred_propia ? 'NO' : 'SI' }}
              </template>
            </Column>
            <Column field="id_tipo_cupo" header="Tipo de Cupo" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.id_tipo_cupo == 1 ? 'FILTRO' : 'DINAMICO' }}
              </template>
            </Column>
            <Column field="dcreacion" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ formatDate(data.dcreacion) }}
              </template>
            </Column>

            <Column field="num_distribuidores" header="Número de Distribuidores" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.num_distribuidores }}
              </template>
            </Column>


            <Column field="dborrado_virtual" header="Fecha de borrado" :sortable="true" bodyStyle="text-align: center;"
                    v-if=" filters && filters.displayDeleted">
              <template #body="{data}">
                {{ data.dborrado_virtual && formatDate(data.dborrado_virtual) }}
              </template>
            </Column>
            <Column field="cstatus_banca" header="Acción" style="width:10px;">
              <template #body="props">
                <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_banca!==4"
                        v-bind:class="{
                                    'locked': props.data.cstatus_banca === 2,
                                    'unlocked': props.data.cstatus_banca === 1,
                                    'fully-locked': props.data.cstatus_banca === 3
                                  }"
                        @click="confirmBlockBanking(props.data)"></span>
                  <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                        v-tooltip.bottom="'Editar'"
                        @click="editBanking(props.data)"></span>
                </div>
              </template>
            </Column>
          </DataTable>

        </div>
      </div>
    </div>
  </div>

  <EditBankingDialog
      :banking="banking"
      :bankingDetails="bankingDetails"
      :show="displayEditBanking"
      ref="editBankingDialog"
      @new-user="pushNewBanking"
      @close="closeBankingDialog"/>

  <Dialog v-model:visible="blockBankingDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="banking">¿{{
          this.banking.cstatus_banca === 2 ? 'Desbloquear' : 'Bloquear'
        }} banca <b>{{ banking && banking.tbanca }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockBankingDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockBanking(banking)"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteBankingsDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Borrar las bancas seleccionadas?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteBankingsDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedBankings"/>
    </template>
  </Dialog>

  <Toast/>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Filter from "@/components/Filters/Filter";
import {FilterMatchMode} from "primevue/api";
import EditBankingDialog from "@/components/Settings/Bankings/Dialog/EditBankingDialog";
import BankingService from "@/service/backend/BankingService";

export default {
  components: {
    'Filter': Filter,
    'EditBankingDialog': EditBankingDialog,
  },
  mixins: [generalMixin],
  data() {
    return {
      bankings: null,
      totalRecords: null,
      identity: null,
      loadingTable: false,
      searchFilters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id_banca': {value: '', matchMode: 'contains'},
        'tbanca': {value: '', matchMode: 'contains'},
        'dcreacion': {value: '', matchMode: 'contains'},
      },
      filterData: null,
      displayEditBanking: false,
      banking: null,
      bankingDetails: null,
      overlay: false,

      deleteBankingsDialog: null,
      selectedBankings: null,
      selectAll: null,

      blockBankingDialog: false,
      deleteBankingDialog: false,

    }
  },
  userService: null,
  created() {
    this.bankingService = new BankingService;
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      searchFilters: this.searchFilters
    };
    await this.getData();
  },
  methods: {
    async getData(filters) {
      this.selectedBankings = null;
      if (filters) {
        filters.lista = null;
        this.filterData = filters;
      }
      this.lazyParams.identity = this.identity;
      this.lazyParams.filters = this.filterData;
      let payload = Object.assign(this.lazyParams);
      this.loadingTable = true;
      const data = await this.bankingService.getAll(payload);
      this.bankings = data.rows;
      this.totalRecords = Number(data.total);
      this.loadingTable = false;
    },
    onPage(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onFilter() {
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.bankingService.getAll().then(data => {
          this.selectAll = true;
          this.selectedBankings = data.bankings;
        });
      } else {
        this.selectAll = false;
        this.selectedBankings = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedBankings.length === this.totalRecords;
    },
    onRowUnselect() {
      this.selectedBankings = false;
    },
    createBanking() {
      this.$store.commit('setBanking', {});
      this.$router.push('/bancas/agregar-1');
    },
    async editBanking(banking) {
      this.overlay = true;
      this.banking = {...banking};
      this.displayEditBanking = true;
    },
    closeBankingDialog() {
      this.displayEditBanking = false;
      this.getData();
      this.overlay = false;
    },
    pushNewBanking(value) {
      this.bankings.push(value);
      this.closeBankingDialog();
    },
    confirmDeleteSelected() {
      this.deleteBankingsDialog = true;
    },
    async deleteSelectedBankings() {
      // this.loadingTable = true;
      let idsToDelete = [];
      this.selectedBankings.forEach((item) => {
        idsToDelete.push(item.id)
      });
      this.deleteBankingsDialog = false
      const response = await this.bankingService.deleteBanking({idsToDelete: idsToDelete.toString()});
      this.bankings = this.bankings.filter(val => !this.selectedBankings.includes(val));

      this.selectedBankings = null
      // this.loadingTable = false;
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Banca(s) no eliminada(s) | ' + response.error,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: 'Banca(s) eliminada(s)', life: 3000});
      }


    },
    confirmBlockBanking(banking) {
      this.banking = banking;
      this.blockBankingDialog = true;
    },
    async blockBanking() {
      this.loadingTable = true;
      this.blockBankingDialog = false;

      const response = await this.bankingService.blockBanking({
        id_banca: this.banking.id_banca,
        cstatus_banca: this.banking.cstatus_banca,
      });
      await this.getData();
      this.loadingTable = false;

      const preaction = this.banking.cstatus_banca === 2 ? 'des' : '';
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `Agencia no pudo ser ${preaction}bloqueada | ${response.error}`,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: `Agencia ${preaction}bloqueada`, life: 3000});
      }

    }

  },

}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

#role, #status {
  margin-top: 10px;
}

.field {
  padding: 0 20px 0 20px;
}

.fully-locked {
  //background-color: #db3636;
  border-color: #db3636;
  color: #db3636;
}

.locked {
  //background-color: #ffc107;
  border-color: #ffc107;
  color: #ffc107;
}

.unlocked {
  //background-color: var(--surface-400);
  border-color: var(--surface-400);
  //color: var(--green-banklot)
}

.normal {
  background-color: var(--surface-400);
  border-color: var(--surface-400);
  color: var(--green-banklot)
}

.status-badge {
  //width: 86px; //max-content;'
}

.edit-icon {
  margin: 10px;
}

</style>