import BaseService from "./BaseService";

class BankingService extends BaseService {

    async getAll(req) {
        const response = await this.axios.post('/banking/all', req);
        return await response.data;
    }

    async getDetails(req) {
        const response = await this.axios.post('/banking/details', req);
        return await response.data;
    }

    async deleteBanking(req) {
        const response = await this.axios.post('/banking/delete', req);
        return response.data;
    }

    async blockBanking(req) {
        const response = await this.axios.post('/banking/block', req);
        return response.data;
    }

    async createBanking(req) {
        const response = await this.axios.post('/banking/create', req);
        return response.data;
    }

    async updateBankingDetails(req) {
        const response = await this.axios.post('/banking/update', req);
        return response.data;
    }

    async getPaymentProfile(req) {
        const response = await this.axios.post('/banking/payment_profile', req);
        return response.data;
    }

    async getBankingPercentages(req) {
        const response = await this.axios.post('/banking/percentages', req);
        return response.data;
    }

    async updateBankingPercentages(req) {
        const response = await this.axios.post('/banking/percentages/update', req);
        return response.data;
    }

    async getBankingMiscellaneous(req) {
        const response = await this.axios.post('/banking/miscellaneous', req);
        return response.data;
    }

    async getQuota(req) {
        const response = await this.axios.post('/banking/quota', req);
        return response.data;
    }

    async fecthBankingPsxcmxban(req) {
        const response = await this.axios.post('/banking/fetch_psxcmxban', req);
        return response.data;
    }

    async getDistributors(req) {
        const response = await this.axios.post('/banking/distributors', req);
        return response.data;
    }

}

export default BankingService
