<template>
  <div>


    <DataTable :value="misc && misc.limit"
               editMode="row" dataKey="id"
               v-model:editingRows="editingRows"
               @row-edit-save="onRowEditSaveMisc"
               responsiveLayout="scroll"
               :loading="loadingTable"
               class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">

      <Column field="tlista" header="Lista" style="text-align: left;"/>

      <Column field="min" header="Apuesta Mínima" style="text-align: center;">
        <template #body="{data}">
          {{ data.min }}
        </template>
        <template #editor="{ data, field }">
          <InputNumber v-model="data[field]" prefix="" :min="0" :max="40" showButtons/>
        </template>
      </Column>

      <Column field="max" header="Apuesta Máxima" style="text-align: center;">
        <template #body="{data}">
          {{ data.max }}
        </template>
        <template #editor="{ data, field }">
          <InputNumber v-model="data[field]" prefix="" :min="0" :max="40" showButtons/>
        </template>
      </Column>
<!--      <Column :rowEditor="true" bodyStyle="text-align:center"></Column>-->
    </DataTable>
  </div>
<!--  <div class="flex justify-content-end">-->
<!--    <Button icon="pi pi-print" class="p-button-raised" :onClick="print"/>-->
<!--  </div>-->

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import BankingService from "@/service/backend/BankingService";

export default {
  name: "Tab4",
  mixins: [generalMixin],
  created() {
    this.bankingService = new BankingService;
  },
  props: {
    banking: Object,
  },
  mounted() {
    this.getMisc();
  },
  data() {
    return {
      loadingTable: false,
      editingRows: [],
      misc: null,
    }
  },
  methods: {
    async getMisc() {
      this.loadingTable = true;
      this.misc = await this.bankingService.getBankingMiscellaneous({
        id_comercializador: this.banking.id_comercializador,
        id_banca: this.banking.id_banca,
        id_psxcmxban: this.banking.id_psxcmxban,
      });
      this.loadingTable = false;
    },
    onRowEditSaveMisc(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.misc.loteria[index] = newData;
    },
  }

}
</script>

<style scoped lang="scss">


.content {
  width: 100%;
}

.input-text {
  margin: 30px 0 30px 0;
}

.address {
  border: solid 1px lightgray;
  border-radius: 5px;
  margin-top: 5px;
  padding: 15px;
}

.address-label {
  //top: -0.75rem;
  margin-left: 12px;
  font-size: 12px;
  color: gray;
}

.container {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--surface-300);
  margin: 35px 0 15px 0;

  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 10px 10px 10px 15px;
    min-width: 140px;
    font-weight: bold;
  }

  .content {
    padding: 10px 0 0 0;
    min-width: 70px;
    text-align: center;
    font-weight: bold;
  }
}

.table {
  display: block;
}

.row {
  display: block;
}

.cell {
  display: inline-block;
}

.p-button-raised {
  margin-top: 10px;
}

</style>